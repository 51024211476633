'use strict';

$(document).ready(function() {

  function getRelatedContent(el) {
    var url = $(el).attr('href');
    var id = url.substring(url.indexOf('#') + 1);
    return $('#' + id);
  }

  // var inview = new Waypoint.Inview({
  //   element: $('.il1')[0],
  //   enter: function(direction) {
  //     $('.il1').fadeIn('slow');
  //     $('.il1').css({ 'margin-top': '20px' });
  //   },
  //   exited: function(direction) {
  //     $('.il1').css({ 'margin-top': '0px' });
  //     $('.il1').fadeOut('slow');
  //   }
  // });

  // var inview2 = new Waypoint.Inview({
  //   element: $('.il2')[0],
  //   enter: function(direction) {
  //     $('.il2').fadeIn('slow');
  //     $('.il2').css({ 'margin-top': '20px' });
  //   },
  //   exited: function(direction) {
  //     $('.il2').css({ 'margin-top': '0px' });
  //     $('.il2').fadeOut('slow');
  //   }
  // });

  // var inview3 = new Waypoint.Inview({
  //   element: $('.il3')[0],
  //   enter: function(direction) {
  //     $('.il3').fadeIn('slow');
  //     $('.il3').css({ 'margin-top': '20px' });
  //   },
  //   exited: function(direction) {
  //     $('.il3').css({ 'margin-top': '0px' });
  //     $('.il3').fadeOut('slow');
  //   }
  // });

  // var inview4 = new Waypoint.Inview({
  //   element: $('.il4')[0],
  //   enter: function(direction) {
  //     $('.il4').fadeIn('slow');
  //     $('.il4').css({ 'margin-top': '20px' });
  //   },
  //   exited: function(direction) {
  //     $('.il4').css({ 'margin-top': '0px' });
  //     $('.il4').fadeOut('slow');
  //   }
  // });

  // var inview5 = new Waypoint.Inview({
  //   element: $('.il5')[0],
  //   enter: function(direction) {
  //     $('.il5').fadeIn('slow');
  //     $('.il5').css({ 'margin-top': '20px' });
  //   },
  //   exited: function(direction) {
  //     $('.il5').css({ 'margin-top': '0px' });
  //     $('.il5').fadeOut('slow');
  //   }
  // });
if($('body').hasClass('about')){
  var awaypoint = new Waypoint({
    element: $('#about')[0],
    handler: function(direction) {
      if (direction === 'down') {
        $('body').removeClass('services').addClass('about');
      } else {
        $('body').removeClass('about').addClass('services');
      }
    },
    offset: '50%'
  });

  var swaypoint = new Waypoint({
    element: $('#services')[0],
    handler: function(direction) {
      if (direction === 'down') {
        $('body').removeClass('about').addClass('services');
      } else {
        $('body').removeClass('services').addClass('about');
      }
    },
    offset: '50%'
  });

  var wwaypoint = new Waypoint({
    element: $('#workflow')[0],
    handler: function(direction) {
      if (direction === 'down') {
        $('body').removeClass('services').addClass('workflow');
      } else {
        $('body').removeClass('workflow').addClass('services');
      }
    },
    offset: '50%'
  });

  var cwaypoint = new Waypoint({
    element: $('#clients')[0],
    handler: function(direction) {
      if (direction === 'down') {
        $('body').removeClass('workflow').addClass('clients');
      } else {
        $('body').removeClass('clients').addClass('workflow');
      }
    },
    offset: '50%'
  });

  var uswaypoint = new Waypoint({
    element: $('#thisisus')[0],
    handler: function(direction) {
      if (direction === 'down') {
        $('body').removeClass('clients').addClass('thisisus');
      } else {
        $('body').removeClass('thisisus').addClass('clients');
      }
    },
    offset: '50%'
  });

  var cowaypoint = new Waypoint({
    element: $('#contact')[0],
    handler: function(direction) {
      if (direction === 'down') {
        $('body').removeClass('thisisus').addClass('contact');
      } else {
        $('body').removeClass('contact').addClass('thisisus');
      }
    },
    offset: '50%'
  });


  $('.main-nav li a').click(function(e) {
    e.preventDefault();
    var myoffset = 70;
    var top = getRelatedContent(this).offset().top;
    var newtop = top - myoffset;
    if (top < 150) {
      newtop = 0;
    }
    //console.log(newtop);
    $('html,body').animate({ scrollTop: newtop });
    $('body').removeClass('menu-open');
  });
}

  $('.lsb').click(function(e) {
    e.stopPropagation();
    var sellang = $(this).data('lang');

    // don't if we are mobile, just show 1 lang max
    if ($(window).width() < 800) {
      $('.lsb').removeClass('alang');
      $(this).addClass('alang');
      // hide other languages except the chosen one
      $('.en,.fr,.de,.nl').hide();
      $('.' + sellang).show();

    } else {
      // toggle language
      if ($(this).hasClass('alang') === true) {
        // deactivate this language
        $(this).removeClass('alang');
        $('.' + sellang).hide();

        // check if there are still quotes, if not show a quote & illustration
        if($('.quotetxt').is(":visible") === false){
          // check which language is still activated
          var lolang = $(document).find('.alang').data('lang');
          var langquote = '.quotetxt.' + lolang;
          var illutxt = '.illutxtholder.' + lolang;
          $(langquote).show();
          $(illutxt).show();
        }
      } else {
        $(this).addClass('alang');
        $('.' + sellang).show();
        // hide all quotes &illustrationtxt and show quote & illutxt in last selected language
        $('.quotetxt').hide();
        $('.illutxtholder').hide();
        var langquote = '.quotetxt.' + sellang;
        var illutxt = '.illutxtholder.' + sellang;
        $(langquote).show();
        $(illutxt).show();
      }

    }


    // loop through languages and (de)activate
    var totalLang = 0;
    var langs = [];
    $('.lsb').each(function(index) {
      if ($(this).hasClass('alang') === true) {
        langs.push($(this).data('lang'));
      }
    });

    $('body').removeClass('langsel0 langsel1 langsel2 langsel3 langsel4').addClass('langsel' + langs.length);
    $('html,body').animate({ scrollTop: 0 });
    Waypoint.refreshAll();

    window.setTimeout(Waypoint.refreshAll, 1000);
  });

  // $('.langselecta').click(function(){
  //   $('.nl, .en, .fr').show();
  //   $('body').removeClass('langsel');
  //   $('html,body').animate({ scrollTop: 0 });
  //   Waypoint.refreshAll();
  // });


  $('.hamburger').click(function() {
    if ($('body').hasClass('menu-open')) {
      $('body').removeClass('menu-open');
    } else {
      $('body').addClass('menu-open');
    }
  });


  function setLanguage() {
    var language = navigator.language || navigator.browserLanguage; //for IE
    language = language.toLowerCase();

    language = 'nl'; // remove before going live
    $('.lsb').removeClass('alang');
    if (language === 'fr' || language === 'fr-be' || language === 'fr-fr') {
      $('.en, .de, .nl').hide();
      $('.sfr').addClass('alang');
      //console.log('french');
    } else if (language === 'en' || language === 'en-us' || language === 'en-gb') {
      $('.fr, .de, .nl').hide();
      $('.sen').addClass('alang');
      //console.log('english');
    } else if (language === 'de') {
      $('.fr, .en, .nl').hide();
      $('.sde').addClass('alang');
      //console.log('english');
    } else {
      $('.en, .de, .fr').hide();
      $('.snl').addClass('alang');
      //console.log('dutchie');
    }
  }

  setLanguage();

  $('.nl').mouseenter(function() {
    $('.en, .de, .fr').addClass('lighter');
  }).mouseleave(function() {
    $('.en, .de, .fr').removeClass('lighter');
  });
  $('.fr').mouseenter(function() {
    $('.en, .de, .nl').addClass('lighter');
  }).mouseleave(function() {
    $('.en, .de, .nl').removeClass('lighter');
  });
  $('.en').mouseenter(function() {
    $('.nl, .de, .fr').addClass('lighter');
  }).mouseleave(function() {
    $('.nl, .de, .fr').removeClass('lighter');
  });
  $('.de').mouseenter(function() {
    $('.en, .nl, .fr').addClass('lighter');
  }).mouseleave(function() {
    $('.en, .nl, .fr').removeClass('lighter');
  });


  // Google maps hack to prevent zoom when scrolling
  $('#map').addClass('scrolloff');  // set the mouse events to none when doc is ready

  $('#contact').on("mouseup",function(){
    // lock it when mouse up
    $('#map').addClass('scrolloff');
  });
  $('#contact').on("mousedown",function(){
    // when mouse down, set the mouse events free
    $('#map').removeClass('scrolloff');
  });
  $("#map").mouseleave(function () {              // because the mouse up doesn't work...
    $('#map').addClass('scrolloff');            // set the pointer events to none when mouse leaves the map area
                                                  // or you can do it on some other event
  });

});
